/* eslint-disable no-underscore-dangle */ /* eslint-disable no-console */
<template>
  <div class="testimonial-section">
    <headerCustom></headerCustom>
    <div class="template main-content sidenav-part ng-scope">
      <h3>Leads</h3>
      <div class="widget">
        <div class="testimonial-content">
          <b-form
            v-promise-btn="{ action: 'submit' }"
            @submit.prevent="saveLeads"
            @reset.prevent="resetForm()"
          >
            <div>
              <!-- <div class="font-18 py-3">
                Information
                <span class="font-12">(* Required information)</span>
              </div> -->

              <div class="ml-auto">
                <b-button to="/viewUserLead" class="back-btn">Back</b-button>
              </div>
              <div>
                <b-form-group>
                  <label>Name</label>
                  <b-input v-model="leads.name" placeholder="Enter Name"></b-input>
                </b-form-group>
              </div>
              <div>
                <b-form-group>
                  <label>Email</label>
                  <b-input type="email" v-model="leads.email" placeholder="Enter Email"></b-input>
                </b-form-group>
              </div>
              <div>
                <b-form-group>
                  <label>Phone</label>
                  <b-input
                    type="number"
                    v-model="leads.phone"
                    placeholder="Enter Phone Number"
                  ></b-input>
                </b-form-group>
              </div>
            </div>
            <div class="pt-2">
              <b-form-group>
                <label>Check In</label>
                <div>
                  <input type="date" v-model="leads.checkIn" />
                </div>
              </b-form-group>
            </div>
            <div class="pt-2">
              <b-form-group>
                <label>Check Out</label>
                <div>
                  <input type="date" v-model="leads.checkOut" />
                </div>
              </b-form-group>
            </div>
            <div>
              <b-form-group>
                <label>No Of Guest</label>
                <b-input
                  type="number"
                  v-model="leads.noOfGuest"
                  placeholder="Enter No of Guest"
                ></b-input>
              </b-form-group>
            </div>
            <div>
              <b-form-group>
                <label>Message</label>
                <b-form-textarea
                  v-model="leads.message"
                  id="textarea"
                  placeholder="Enter message"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div>
              <b-form-group>
                <label>Hotel Name</label>
                <multiselect
                  :taggable="true"
                  track-by="name"
                  :options="hotelList_options"
                  v-model.trim="leads.hotel"
                  :custom-label="customLabel"
                  placeholder="Enter Hotel Name"
                ></multiselect>
              </b-form-group>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import headerCustom from "@/components/Header.vue";
import service from "@/service/apiservice";
import { validationMixin } from "vuelidate";
import Multiselect from "vue-multiselect";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    headerCustom,
    Multiselect
  },
  mixins: [validationMixin],
  data() {
    return {
      id: this.$route.params.id,
      leads: {},
      hotelList_options: [],
      showError: false,
      message1: ""
    };
  },

  created() {
    this.getOneLead();
  },
  methods: {
    resetForm() {
      this.$router.push({ name: "viewUserLead" });
    },
    getOneLead() {
      service.getOneLead(this.id, resultData => {
        if (resultData.status == 200) {
          this.leads = resultData.data;
          if (resultData.data.checkIn) {
            this.leads.checkIn = new Date(resultData.data.checkIn).toISOString().slice(0, 10);
          }
          if (resultData.data.checkOut) {
            this.leads.checkOut = new Date(resultData.data.checkOut).toISOString().slice(0, 10);
          }
          if (!_.isEmpty(resultData.data.message)) {
            this.leads.message = resultData.data.message[0].messageToSend;
          }
        }
      });
    },

    customLabel({ name }) {
      return `${name}`;
    }
  }
};
</script>
<style lang="scss">
@import "src/assets/scss/variables";
.testimonial-section {
  .testimonial-content {
    padding: 20px 20px;
  }
  .back-btn {
    padding: 3px 40px;
    border: 0px;
  }
}
.error-text {
  font-size: 13px;
  color: #dc3545;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
